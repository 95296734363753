;

(function($) {
    $(document).ready(function() {
        var $modal = $('#veissModal'),
            politicaInput = '#politica input',
            politicaSpan = '#politica span',
            comunicacionInput = '#comunicacion input',
            comunicacionSpan = '#comunicacion span',
            privacidad = $(politicaInput).prop('checked');

        /**
         * 1. La política no está aceptada: mostrar modal.
         * 2. La política ya está aceptada: desmarcar.
         */
        $(document).on('click', politicaInput, function(e) {
            if (!privacidad) {
                e.preventDefault();
                modalPrivacidad();

                return;
            }

            toggleChecks(false);
        });

        /**
         * Los clicks en el label de la política de privacidad abren el modal.
         */
        $(document).on('click', politicaSpan, function(e) {
            modalPrivacidad();
        });

        /**
         * Sólo se puede aceptar recibir comunicaciones se se ha leído y aceptado la política de privacidad.
         */
        $(document).on('click', comunicacionInput, function(e) {
            if (privacidad) {
                return;
            }

            e.preventDefault();
            modalComunicacion();
        });

        /**
         * Sólo se puede aceptar recibir comunicaciones se se ha leído y aceptado la política de privacidad.
         */
        $(document).on('click', comunicacionSpan, function(e) {
            if (privacidad) {
                $(comunicacionInput).prop('checked', !$(comunicacionInput).prop('checked'));
                return;
            }

            e.preventDefault();
            modalComunicacion();
        });

        /**
         * Mostrar modal para la privacidad.
         */
        function modalPrivacidad() {
            $modal.veissModal({
                id: 'pol',
                buttons: [{
                    id: 'polnook',
                    class: 'btn btn-default modal-clear',
                    click: function() { toggleChecks(false); }
                }, {
                    id: 'polok',
                    class: 'btn btn-primary btn-serserve-on',
                    click: function() { toggleChecks(true); }
                }]
            });
        }

        /**
         * Mostrar modal para comunicaciones.
         */
        function modalComunicacion() {
            $modal.veissModal({
                id: 'com',
                class: 'alert',
                icon: '/images/alert.svg',
                buttons: [{
                    id: 'comok',
                    class: 'btn btn-primary'
                }]
            });
        }

        /**
         * Cambiar el valor de la propiedad checked.
         */
        function toggleChecks(value) {
            privacidad = value;
            $(politicaInput).prop('checked', value);

            if (!value) {
                $(comunicacionInput).prop('checked', value);
            }
        }
    });
})(jQuery);
