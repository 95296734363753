(function($) {
    $(document).ready(function() {

        //-----------------------------------------------------------------------
        // Cookies
        //-----------------------------------------------------------------------
        $(".footer").cookiesAdvise({
            adviseLocation: "footer", //Donde pone el aviso de cookies
            language: "es", //Idioma para las cadenas
            locale_url: "/js/locale/"
        });

        /*$('.toggle-cookie').on('click', function() {
            $(this).toggleClass('toggled');
            var text = $(this).text();
            console.log(text);
            $(this).text(
                text === "Desactivar" ? "Activar" : "Desactivar");
            Cookies.remove('cookies-advise');
        });*/

        //-----------------------------------------------------------------------
        // Menu fixed on scroll
        //-----------------------------------------------------------------------
        $(window).scroll(navBarFixed);
        navBarFixed();
        function navBarFixed () {

            var overflow = $(document).height() - $(window).height();

            $toFix = $('#header');

            if ($(window).scrollTop() > 50 && overflow > 50) {

                $toFix.addClass('header-fixed');

            } else {

                $toFix.removeClass('header-fixed');

            }

        }
        
        //-----------------------------------------------------------------------
        // Ancla section
        //-----------------------------------------------------------------------
        $('.link-section').on('click', function(e){
            e.preventDefault();
            var strAncla = '#' + $(this).data('ancla');
            $('html,body').animate({scrollTop: $(strAncla).offset().top - 70}, 500);
        });

        //-----------------------------------------------------------------------
        // Form focus
        //-----------------------------------------------------------------------
        $('.form-control').focus(function() {
            
            $(this).parent().find('label').toggleClass('show');

        });

        $('.form-control').focusout(function() {
            
            $(this).parent().find('label').toggleClass('show');

        });

        //-----------------------------------------------------------------------
        // ScrollTo Product Dropdown
        //-----------------------------------------------------------------------
        $('.product-dropdown_text').on('shown.bs.collapse', function() {

            var $dropdown = $(this).closest('.product-dropdown');

            $('html,body').animate({
                scrollTop: $dropdown.offset().top - 100
            }); 

        }); 

        $('.inicio-hero').slick({
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            arrows: false,
            dots: true
          });

    });
})(jQuery);